<template>
  <div class="w-full grid grid-cols-1 gap-4">
    <div class="w-full grid gap-x-2 grid-cols-1 md:grid-cols-2">
      <select
        v-model="month"
        class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border border-gray-200 bg-white rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
      >
        <option
          v-for="(monthElement, monthIndex) in months()"
          :key="monthIndex"
          :value="monthIndex"
          >{{ monthElement }}</option
        >
      </select>
      <select
        v-model="year"
        class="col-span-2 md:col-span-1 mt-2 md:mt-0 w-full disabled:text-gray-400 border border-gray-200 bg-white rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
      >
        <option
          v-for="(yearElement, yearIndex) in years()"
          :key="yearIndex"
          :value="yearElement"
          >{{ yearElement }}</option
        >
      </select>
    </div>
    <div class="col-span-1 h-full">
      <div class="relative pt-1">
        <div class="flex mb-2 items-center justify-between">
          <div>
            <span
              class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200"
            >
              Progreso del mes
            </span>
          </div>
          <div class="text-right">
            <span class="text-xs font-semibold inline-block text-green-600">
              {{ percentage }}%
            </span>
          </div>
        </div>
        <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
          <div
            :style="'width:' + percentage + '%'"
            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
          ></div>
        </div>
      </div>
      <div class="w-full mb-2 flex flex-col justify-items items-start">
        <span class="text-gray-600 font-bold"
          >Número de reservas totales para este mes</span
        >
        <span class="text-gray-500 text-sm font-bold"
          >entre las finalizadas y no finalizadas</span
        >
        <span class="text-gray-600 text-3xl font-bold">{{
          data.total_reservation
        }}</span>
      </div>
      <div class="w-full mb-2 flex flex-col justify-items items-start">
        <span class="text-gray-600 font-bold"
          >Número de reservas finalizadas para este mes</span
        >
        <span class="text-gray-500 text-sm font-bold"
          >reservas finalizadas hasta el momento</span
        >
        <span class="text-gray-600 text-3xl font-bold">{{
          data.total_finish
        }}</span>
      </div>
      <div class="w-full mb-2 flex flex-col justify-items items-start">
        <span class="text-gray-600 font-bold"
          >Dinero generado por reservas</span
        >
        <span class="text-gray-500 w-full text-left text-sm font-bold"
          >corresponde a reservas pasadas ya finalizadas para el mes
          actual</span
        >
        <span class="text-gray-600 text-3xl font-bold">{{
          chileanPeso(data.earnings)
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import PurchaseService from '@/services/purchaseService';

export default {
  name: 'Payments',
  data() {
    return {
      month: moment().month(),
      year: moment().year(),
      data: {
        earnings: 0,
        total_reservation: 0,
        total_finish: 0,
      },
      percentage: 0,
    };
  },
  created() {
    this.callPayments();
  },
  methods: {
    chileanPeso(price) {
      let chileanPeso = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      return chileanPeso.format(price).replace(/,/g, '.');
    },
    months() {
      moment.locale('es');
      return moment.months();
    },
    years() {
      const years = back => {
        const year = new Date().getFullYear();
        return Array.from(
          { length: back },
          (v, i) => year - parseInt(back / 2) + i + 1
        );
      };

      return years(10);
    },
    callPayments() {
      PurchaseService.summaryAdmin(this.doctorprop.id, {
        month: this.month + 1,
        year: this.year,
      })
        .then(response => {
          this.data = response.data;
          if (this.data.total_reservation != 0) {
            this.percentage = Math.floor(
              (this.data.total_finish * 100) / this.data.total_reservation
            );
          } else {
            this.percentage = 0;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
  watch: {
    year() {
      this.callPayments();
    },
    month() {
      this.callPayments();
    },
  },
  computed: {
    ...mapGetters({
      doctorprop: ['admin/user'],
    }),
  },
};
</script>

<style scoped></style>
