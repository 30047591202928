import authAxios from '@/utils/authAxios';

export default {
  purchaseInit: ({ doctor, block_id }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/purchase', {
          doctor,
          block_id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  summary: ({ month, year }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/purchase_summary', {
          month,
          year,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  summaryAdmin: (id, { month, year }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/purchase_summary_admin', {
          month,
          year,
          id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  return: purchase => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/purchase_return', {
          purchase_id: purchase,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fail: purchase => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/purchase_fail', {
          purchase_id: purchase,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAll: () => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(process.env.VUE_APP_BACKEND_URL + '/purchases')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getNext: () => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(process.env.VUE_APP_BACKEND_URL + '/purchases_next')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
